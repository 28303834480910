import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import * as React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import ClientSlider from '../components/clientSlider';
import Layout from 'components/layout';
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";
import Slider from '../components/slider';
import build from 'images/build.svg';
import comp from 'images/comp.svg';
import extension from 'images/extension.svg';
import icon from 'images/icon.svg';
import incentive from 'images/incentive.svg';
import money from 'images/attach_money.svg';
import motivate from 'images/motivate.svg';
import supervisor from 'images/supervisor_account.svg';

const IndexPage = () => {
  return (
    <Layout>
      <main className="main">
        <div>
          <Helmet>
            <link rel="icon" href={icon} />
            <title>Motivate Sales Team with #1 Commission Software</title>
            <meta name="description" content="Design customizable sales compensation plans with No Coding and No Spreadsheet - just a simple platform" />
          </Helmet>
          <div className="Home">
            <Col xs={12} md={8} lg={6}>
              <p className="title"> Motivate Sales Team with #1 Commission Software</p>
              <p className="description">Design Customizable sales compensation plans with <b>No Coding</b> and <b>No Spreadsheet</b> - Just a simple Platform</p>
              <Link to='/demo'>
                <Button className="button" type="primary" >Schedule a Demo</Button>
              </Link>
            </Col>
          </div>
          <ClientSlider />
          <div style={{ marginBottom: '50px' }}>
            <p className="heading">A new paradigm to commissionable sales</p>
          </div>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col xs={{ span: 10 }} md={4} style={{ marginBottom: '30px' }}>
              <Card>
                <Card.Img className='img' variant="top" src={build} />
                <Card.Title className="title">Build Compensation Plan</Card.Title>
                <Card.Text className='text'>Handle most complex sales force compensation plans with a simple, easy to use <b>no-code plan builder</b></Card.Text>
              </Card>
            </Col>
            <Col xs={{ span: 10 }} md={4} style={{ marginBottom: '30px' }}>
              <Card>
                <Card.Img className='img' variant="top" src={money} />
                <Card.Title className="title">Reliable Commission System</Card.Title>
                <Card.Text className='text'>Give sales reps full view into how their incentives are calculated. They will never question <b> how much they earned </b></Card.Text>
              </Card>
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: "50px" }}>
            <Col xs={{ span: 10 }} md={4} style={{ marginBottom: '30px' }}>
              <Card>
                <Card.Img className='img' variant="top" src={extension} />
                <Card.Title className='title'>Sales Performance Management</Card.Title>
                <Card.Text className='text'>Manage multiple reps with personalized Comp Pans on a single dashboard. Get insights and forecast attainment. </Card.Text>
              </Card>
            </Col>
            <Col xs={{ span: 10 }} md={4} style={{ marginBottom: '30px' }}>
              <Card>
                <Card.Img className='img' variant="top" src={supervisor} />
                <Card.Title className='title'>Seamless Integrations</Card.Title>
                <Card.Text className='text'>Integratoins with major CRM’s like HubSpot, Salesforce, Zoho and Pipedrive. </Card.Text>
              </Card>
            </Col>
          </Row>
          <div className='center' style={{ marginBottom: '50px' }}>
            <Link to='/features'>
              <Button className="button" type="primary">More Features</Button>
            </Link>
          </div >
          <div style={{ marginBottom: '50px' }}>
            <p className='heading'>No More Excel Templates for Sales Incentive Calculation</p>
          </div>
          <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '50px' }}>
            <Col xs={{ span: 10 }} md={{ span: 5, offset: 1 }}>
              <img src={comp} style={{ width: '100%' }} alt="something went wrong" />
            </Col>
            <Col xs={{ span: 10 }} md={4}>
              <div>
                <p className="head">BUILD COMP PLANS</p>
                <p className="desc"> Build Comp Plans that suits your sales team.
                  Design from scratch or select from templates. Customizable Rules. Easy Access to CRM Data.</p>
              </div>
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '50px' }}>
            <Col xs={{ span: 10, order: 'last' }} md={{ span: 4, order: 'first' }}>
              <div>
                <p className="head">INCENTIVE TRANSPARENCY</p>
                <p className="desc">Give reps complete visibility into how their incentives are calculated. They will never question how much they earned and why.</p>
              </div>
            </Col>
            <Col xs={{ span: 10, order: 'first' }} md={{ span: 5, offset: 1, order: 'last' }}>
              <img src={incentive} style={{ width: '100%' }} alt="something went wrong" />
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '50px' }}>
            <Col xs={{ span: 10 }} md={{ span: 5, offset: 1 }}>
              <img src={motivate} style={{ width: '100%' }} alt="something went wrong" />
            </Col>
            <Col xs={{ span: 10 }} md={4}>
              <div>
                <p className="head">MOTIVATE SALES TEAM</p>
                <p className="desc"> Show real-time and detailed commission statements to sales reps. Focus on key metrics in sales and AI engine will suggest you ways to improve Quota attainment. </p>
              </div>
            </Col>
          </Row>
          <div>
            <p className='heading'>What our clients say</p>
          </div>
          <Slider />
          <div style={{ justifyContent: 'center', textAlign: 'center' }}>
            <p className='heading'>We think QuotaCal can motivate your sales team. Give it a try.</p>
            <Link to='/demo'>
              <Button className="button" type="primary" style={{ marginBottom: '50px' }} >Schedule a Demo</Button>
            </Link>
            <p style={{ color: '#000000', fontSize: '20px', opacity: '0.5', marginBottom: '80px', fontFamily: 'Inria Sans' }}>Once you schedule a demo, one of our account managers will be assigned to take you through the platform.</p>
          </div>
        </div >
      </main>
    </Layout>
  )
};

export default IndexPage