import Carousel from 'react-multi-carousel';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import khaitan from 'images/khaitan.svg';
import rrkabel from 'images/rrkabel.svg';
import sutherland from 'images/sutherland.svg';

const responsive = {
    desktop: {
        breakpoint: { max: 2000, min: 768 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1200, min: 576 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
    }
};

const ClientSlider = ({ deviceType }) => {
    return (
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Col>
                <Carousel
                    arrows={false}
                    ssr
                    autoPlay
                    infinite
                    autoPlaySpeed={5000}
                    partialVisbile
                    deviceType={deviceType}
                    itemClass="image-item"
                    responsive={responsive}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div>
                        <img
                            style={{ height: '100px', width: '250px' }}
                            alt="something went wrong!"
                            src={khaitan}
                        />
                    </div>
                    <div>
                        <img
                            style={{ height: '100px', width: '250' }}
                            alt="something went wrong!"
                            src={rrkabel}
                        />
                    </div>
                    <div>
                        <img
                            style={{ height: '100px', width: '250' }}
                            alt="something went wrong!"
                            src={sutherland}
                        />
                    </div>
                    <div>
                        <img
                            style={{ height: '100px', width: '250' }}
                            alt="something went wrong!"
                            src={rrkabel}
                        />
                    </div>
                    <div>
                        <img
                            style={{ height: '100px', width: '250' }}
                            alt="something went wrong!"
                            src={khaitan}
                        />
                    </div>
                    <div>
                        <img
                            style={{ height: '100px', width: '250' }}
                            alt="something went wrong!"
                            src={sutherland}
                        />
                    </div>
                </Carousel>
            </Col>
        </Row>
    )
}

export default ClientSlider;
